@import '~packages/styles/_colors';

.addButton {
  color: $hk-lake;
  font-size: 16px;
  font-weight: 600;
  margin-left: 24px;
  margin-right: 16px;
}

.buttonContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > div {
    margin-left: 8px;
  }
}
