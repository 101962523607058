@import '~app/fieldapp/_variables';

.container {
  display: flex;
  flex-direction: column;
  min-height: 50vh;

  @include screenSizeDesktop {
    width: 450px;
  }
}

.contentContainer {
  flex-grow: 1;
}

.modalTitle {
  font-size: 21px;
  line-height: 25px;
  font-weight: 700;
  text-align: center;
}

.label {
  font-size: 14px;
  line-height: 16px;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 8px;
  display: block;
}

.todayDateContainer {
  margin-bottom: 25px;
}

.todayDate {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

.unitSelectContainer {
  margin-bottom: 25px;
}

.actionsContainer {
  display: flex;
  margin-top: 30px;
}
