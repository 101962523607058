@import '~packages/styles/colors';

.ticketRoutingCard {
  position: relative;
  min-height: 150px;
}

.row {
  align-items: center;
  display: flex;
  margin: 0 24px;
  padding: 16px 0;
  position: relative;

  & + & {
    border-top: 1px solid $hk-midnight-20;
  }
}

.ticketsLink,
.ticketsLink:hover,
.ticketsLink:visited {
  color: $hk-lake;
}

.title {
  flex-grow: 2;
}

.status {
  padding: 0 16px;
}

.editRow {
  display: block;
  flex-direction: column;
}

.editRowAssignee {
  align-items: center;
  display: flex;

  .editRowAssigneeTitle {
    margin-right: 40px;
  }
}

.editRowButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

.assignee {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  padding-right: 16px;
}

.noAssignee {
  color: $hk-dusk-lighter;
  font-style: italic;
}

.alert {
  box-shadow: 0 2px 10px 0 rgba(44, 52, 57, 0.15);
  margin: 0;
}

.edit {
  margin-top: 4px;
}
