@import '~packages/styles/_mixins';

@mixin zoneMangeBG {
  background: $hk-midnight-10;
}

.pageContentWrapper {
  @include pageContentArea;
}

.headerWrapper {
  @include zoneMangeBG;
}

.headerContent {
  @include constrainAndCenter(1000px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-top: 24px;
}

.editBtn {
  color: $hk-lake;
}

// styling passed on TabbedContent component
.tabbedWrapper {
  @include zoneMangeBG;
}

.tabbedContentControls {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
}

.tabbedContent,
.tabbedContentWrapper {
  @include constrainAndCenter(1000px);
  @include zoneMangeBG;
  padding-left: 16px;
  width: 100%;
}

.modalButtonsWrapper {
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.zoneTitleWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: auto;

  .title {
    font-size: 21px;
    font-weight: 600;
    margin: 0;
  }
}
