@import '~packages/styles/_colors';

.selector {
  border-radius: 2px;
  border: 2px $hk-lake-40;
  flex-grow: 2;
  margin-right: 16px;

  :global(.asyncSelector__control) {
    border-radius: 2px;
    border: 1px solid $hk-midnight-30;
    color: $hk-dusk;
    font-weight: 500;
    height: 40px;
    line-height: 24px;
    outline: none;
  }

  :global(.asyncSelector__value-container) {
    padding: 0 16px;
  }

  :global(.asyncSelector__single-value) {
    left: 0;
    margin: 0;
    padding-left: 16px;
  }

  :global(.asyncSelector__control--is-focused) {
    box-shadow: none;
    outline-offset: -2px;
    outline: 4px auto $hk-lake !important;
    transition: none;

    &:hover {
      border-color: $hk-midnight-30;
    }
  }

  :global(.asyncSelector__indicator-separator) {
    display: none;
  }

  :global(.asyncSelector__dropdown-indicator) {
    display: none;
  }

  :global(.asyncSelector__menu) {
    border-radius: 1px;
  }

  :global(.asyncSelector__option) {
    color: inherit;
  }

  :global(.asyncSelector__option--is-selected) {
    background-color: $hk-midnight-10;
  }
}

.noSearch {
  :global(.asyncSelector__loading-indicator) {
    display: none;
  }

  :global(.asyncSelector__menu) {
    display: none;
  }
}
