@import '~packages/styles/_colors';

.addUnitModalContent {
  color: $hk-dusk;
  font-weight: normal;
}

.resultsCount {
  color: $hk-dusk-lighter;
  font-size: 14px;
}

.resultsCount {
  font-weight: 600;
}

.searchSection {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.contentSection {
  margin-top: 16px;
  position: relative;
}

.emptySearchWrapper {
  min-height: 160px;
}

.subText {
  color: $hk-dusk-lighter;
  font-size: 14px;
}
