.unitDetailIcons {
  align-items: center;
  display: flex;
  width: 100%;
}

.predictedCleanTime {
  font-size: 12px;
  margin-left: 16px;
}
