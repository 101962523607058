@import '~packages/styles/_colors';

.preference {
  align-items: stretch;
  display: flex;
}

.editingPreference {
  &:hover {
    background-color: rgba(44, 52, 57, 0.04);

    .removePreference {
      visibility: visible;
    }
  }
}

.preferenceContent {
  flex: 1 1 0;
  margin-left: 8px;
}

.removePreference {
  cursor: pointer;
  display: flex;
  padding: 0 8px;
  visibility: hidden;
}

.addPreference {
  color: $hk-lake;
  cursor: pointer;
  font-weight: 600;
  margin-top: 8px;
  text-transform: capitalize;
  width: fit-content;

  &:first-child {
    margin-top: 0;
  }
}
