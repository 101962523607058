.drawerContainer {
  min-width: 320px;
  height: 100vh;
  padding: 48px 32px 32px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.header {
  color: rgb(44, 52, 57);
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 16px;
}

.filterName {
  color: rgb(44, 52, 57);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.ratingAndChannelContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  gap: 50px;
  flex-grow: 1;
}

.ratingAndChannelContainer > div,
.checkboxes {
  display: flex;
  flex-direction: column;
}

.unitContainer {
  margin-bottom: 25px;
}

.applyButton {
  padding: 10px 16px;
}
