@import '~packages/styles/_colors';

.nameSection {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
  width: 100%;

  .zoneNameHeader {
    padding-bottom: 4px;
  }

  .zoneNameInput {
    width: 100%;
  }
}

.managerSection {
  display: flex;
  flex-direction: column;
  padding-bottom: 48px;
  .sectionHeader {
    padding-bottom: 4px;
  }
  .addManagerButtonWrapper {
    display: flex;
    justify-content: flex-start;

    .addManagerButton {
      padding: 0;
    }
  }
}

.buttonSection {
  display: flex;
  justify-content: flex-end;
}

.userSelectorWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;

  .closeIcon {
    color: $hk-alertness;
  }
}

.edit {
  margin-left: 16px;
  margin-top: 4px;
}
