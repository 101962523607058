@import '~packages/styles/_colors';

.unitCode {
  color: $hk-midnight;
  font-size: 14px;
  font-weight: 600;
}

.title {
  color: $hk-midnight;
  font-size: 21px;
  font-weight: 600;
  margin-top: 8px;
}

.body {
  font-size: 14px;
  margin-top: 8px;
}

.label {
  color: $hk-midnight;
  font-size: 14px;
  font-weight: 600;
  margin-top: 24px;
  margin-bottom: 4px;
}

.reason {
  margin-top: 16px;
}
