@import '~packages/styles/colors';
@import '~packages/styles/mixins';

.ticketRoutingPage {
  @include constrainAndCenter(1200px);
  width: 100%;
}

.ticketsLink,
.ticketsLink:hover,
.ticketsLink:visited {
  color: $hk-lake;
}

.leadText {
  padding: 22px 8px 16px;
  border-bottom: 1px solid $hk-midnight-20;
  margin-bottom: 16px;
}

.tierOneRow {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}

.tierOneTitle {
  font-size: 21px;
  line-height: 32px;
  margin-bottom: 16px;
}

.tierOneContainer {
  min-height: 200px;
  position: relative;
}

.tierTwoContainer {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
}

.dispositionCard {
  width: 90%;
  margin: auto;
  margin-bottom: 32px;

  @media only screen and (min-width: 769px) {
    width: calc(50% - 12px);

    &:nth-of-type(odd) {
      margin: inherit;
    }

    &:nth-of-type(even) {
      margin-left: 12px;
    }
  }
}
