@import '~packages/styles/_colors';

.filtersContainer {
  display: flex;
  justify-content: flex-end;
  padding: 10px 15px;
  background-color: $hk-midnight;
}

.table {
  display: grid;
  grid-template-columns: repeat(4, 1fr) 3fr;
}

.header,
.body,
.bodyRow {
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1 / -1;
}

.header > .headerCell:not(:last-child) {
  border-right: 1px solid $hk-midnight-10;
}

.headerCell {
  text-transform: uppercase;
  text-align: center;
  color: $hk-midnight;
  font-weight: bold;
  border-bottom: 3px solid $hk-midnight-10;
  padding: 20px 10px;
}

.bodyRow {
  border-bottom: 1px solid $hk-midnight-10;
}

.bodyCell {
  padding: 20px 10px;
}

.bodyRow > .bodyCell:not(:nth-child(5n)) {
  border-right: 1px solid $hk-midnight-10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationContainer {
  height: 50px;
}

.bodyRow .unitCode,
.bodyRow .reviewDate {
  color: $hk-midnight;
}

.bodyRow {
  color: $hk-dusk;
}
