@import '~packages/styles/_colors';

.addHkModalContent {
  font-weight: normal;
  color: $hk-dusk;
}

.headerNotes,
.resultsCount {
  color: $hk-dusk-lighter;
  font-size: 14px;
}

.headerNotes {
  margin-bottom: 16px;
}

.resultsCount {
  font-weight: 600;
}

.searchSection {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.contentSection {
  margin-top: 16px;
  position: relative;
}

.emptySearchWrapper {
  min-height: 160px;
}

.nameColumn {
  padding-right: 24px;
}

.subText {
  color: $hk-dusk-lighter;
  font-size: 14px;
}

.searchHkTab {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.tabContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .tabText {
    &:last-child {
      margin-left: 8px;
    }
    &:first-child {
      margin-right: 8px;
    }
  }
}

.zoneItem > :first-child {
  display: flex;
  justify-content: center;
}
